/*show only on mobile*/
.only-mobile {
  @media (--viewport-tablet) {
    display: none;
  }
}

/*show only on mobile and tablet*/
.only-mobile-tablet {
  @media (--viewport-desktop) {
    display: none;
  }
}

/*show only on tablets and desktop*/
.only-tablet-desktop {
  display: none;

  @media (--viewport-tablet) {
    display: block;
  }
}

/*show only on desktop*/
.only-desktop {
  display: none;

  @media (--viewport-desktop) {
    display: block;
  }
}

