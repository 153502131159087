.simple-text {
  color: var(--text-color-secondary);
  font-family: var(--font-family);
  hyphens: auto;

  h1 {
    margin-bottom: 40px;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-family);
    color: var(--text-color-primary);

  }

  h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 1.75rem;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-family);
    color: var(--text-color-primary);

  }

  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.375rem;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-family);
    color: var(--text-color-primary);

  }

  h4 {
    margin-bottom: 20px;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-family);
    color: var(--text-color-primary);
  }

  h5 {
    margin-bottom: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-family);
    color: var(--text-color-primary);
  }

  p {
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-color-secondary);
    font-family: var(--font-family);
  }

  strong {
    font-family: var(--font-family);
    font-weight: 600;
  }

  ul, ol {
    margin-bottom: 20px;
    line-height: 1.5;
    list-style: circle;
    font-family: var(--font-family);

    li {
      margin-left: 25px;
      margin-bottom: 10px;
      font-family: var(--font-family);
    }
  }

  ul {
    list-style: disc;
    font-family: var(--font-family);
  }

  ol {
    list-style: decimal;
    font-family: var(--font-family);
  }


  a {
    color: var(--text-color-primary);
    hyphens: auto;
    font-family: var(--font-family);
  }

  hr {
    display: none;
  }
}
