@custom-media --viewport-mobile (width >= 23rem); /*370 px*/
@custom-media --viewport-tablet (width >= 48rem); /*768 px*/
@custom-media --viewport-desktop (width >= 64rem); /*1024 px*/
@custom-media --viewport-desktop-big (width >= 77rem); /*1235 px*/

:root {
  --text-color-primary: #13214A;
  --text-color-secondary: #6B7A8B;
  --text-color-secondary-light: #5E71AF;
  --text-color-tertiary: #9EAABA;
  --primary-color: #00A8FF;
  --primary-color-dark: #284394;
  --accent-color: #1BB934;
  --color-white: #ffffff;
  --color-black: #0A1025;
  --light-background: #F6FAFC;
  --divider-color: #EFF1F3;
  --error-color: #FC4949;
  --font-family: 'ProximaNova', Arial, sans-serif;

  /*Heights*/
  --desktop-header-height: 79px;
  --mobile-header-height: 60px;
  --desktop-sticky-header-height: 64px;
  --mobile-popup-header-height: 60px;
  --tablet-popup-header-height: 80px;
  --desktop-popup-header-height: 64px;
}
