<template>
  <div class="pricing-header">
    <div class="pricing-header__box pricing-header__box--logo">
      <router-link class="pricing-header__logo-link" :to="{ name: 'landingHomePage' }">
        <img class="pricing-header__logo" src="/images/landing/gccs-logo.svg" alt="logo">
      </router-link>
    </div>
    <div class="pricing-header__box">
      <h1 class="pricing-header__title secondary-title">Affordable tool for your smart management process</h1>
      <p class="pricing-header__text text-secondary">Choose your plan and get access to the best market solution for workforce agencies who need to deploy employees to multiple client sites.</p>
    </div>
  </div>
</template>

<style scoped>
  .pricing-header {
    text-align: center;
  }

  .pricing-header__title {
    margin-bottom: 13px;
  }

  .pricing-header__text {
    @media (--viewport-desktop) {
      margin: 0 auto;
      max-width: 632px;
    }
  }

  .pricing-header__box--logo {
    margin-bottom: 20px;
    padding: 14px 0;

    @media (--viewport-desktop) {
      padding: 24px 0;
      margin-bottom: 50px;
    }
  }

  .pricing-header__logo {
    width: 122px;
    height: 32px;
  }

  .pricing-list__title {
    margin-bottom: 16px;

    @media (--viewport-desktop) {
      margin-bottom: 8px
    }
  }

</style>
