<template>
  <div class="privacy-policy-header">
    <div class="privacy-policy-header__box privacy-policy-header__box--logo">
      <router-link class="privacy-policy-header__logo-link" :to="{ name: 'landingHomePage' }">
        <img class="privacy-policy-header__logo" src="/images/landing/gccs-logo.svg" alt="logo">
      </router-link>
    </div>
    <div class="privacy-policy-header__box">
      <h1 class="privacy-policy-header__title secondary-title">Privacy Policy</h1>
      <p class="privacy-policy-header__text text-secondary"></p>
    </div>
  </div>
</template>

<style scoped>
  .privacy-policy-header {
    text-align: center;
  }

  .privacy-policy-header__title {
    margin-bottom: 13px;
  }

  .privacy-policy-header__text {
    @media (--viewport-desktop) {
      margin: 0 auto;
      max-width: 632px;
    }
  }

  .privacy-policy-header__box--logo {
    margin-bottom: 20px;
    padding: 14px 0;

    @media (--viewport-desktop) {
      padding: 24px 0;
      margin-bottom: 50px;
    }
  }

  .privacy-policy-header__logo {
    width: 122px;
    height: 32px;
  }

  .privacy-policy-list__title {
    margin-bottom: 16px;

    @media (--viewport-desktop) {
      margin-bottom: 8px
    }
  }

</style>
