.visible {
  opacity: 0;
  transform: translateY(100px);
  animation: slideUp 1s linear forwards;
  animation-delay: .3s;
}


@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
