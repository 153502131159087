.section-title {
    margin-bottom: 11px;
    text-transform: uppercase;
    color: var(--primary-color);
    letter-spacing: 0.75px;
    font-size: 1rem;
    line-height: 1.5;
    font-family: var(--font-family);
    font-weight: 600;
}

.secondary-title {
    color: var(--text-color-primary);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25;
    font-family: var(--font-family);
}

.text-primary {
    color: var(--text-color-primary);
    font-size: 1rem;
    line-height: 1.5;
    font-family: var(--font-family);
}

.text-secondary {
    color: var(--text-color-secondary);
    font-size: 1rem;
    line-height: 1.5;
    font-family: var(--font-family);
}

.text-tertiary {
    color: var(--text-color-tertiary);
    font-size: 1rem;
    line-height: 1.5;
    font-family: var(--font-family);
}

