<template>
  <div class="input-tooltip">
    <svg class="input-tooltip__ico">
      <use href="#info"/>
    </svg>
    <div class="input-tooltip__text">
      <slot/>
    </div>
  </div>
</template>

<style scoped>
  .input-tooltip {
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 99;
    cursor: help;

    &:hover {
      .input-tooltip__ico {
        opacity: 1;
      }

      .input-tooltip__text {
        display: block;
      }
    }
  }

  .input-tooltip__ico {
    width: 100%;
    height: 100%;
    fill: var(--primary-color);
    opacity: 0.6;
  }

  .input-tooltip__text {
    display: none;
    position: absolute;
    top: calc(100% + 11px);
    right: 0;
    width: 185px;
    padding: 8px;
    text-align: center;
    background-color: #373F51;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 0.75rem;
    color: var(--color-white);
    line-height: 1.25;
    cursor: help;
    pointer-events: none;

    &:before {
       content: '';
       position: absolute;
       bottom: 100%;
       right: 7px;
       width: 0;
       height: 0;
       border-left: 4px solid transparent;
       border-right: 4px solid transparent;
       border-bottom: 4px solid #373F51;
    }

    @media(--viewport-tablet) {
      right: 50%;
      transform: translateX(50%);
      &:before {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
</style>
