.button {
  position: relative;
  margin: 0;
  padding: 0 20px;
  color: #fff;
  font-size: 1rem;
  line-height: 2.5;
  outline: 0;
  font-weight: 600;
  font-family: var(--font-family);
  border-radius: 2px;
  user-select: none;
  cursor: pointer;
  vertical-align: top;
  box-sizing: border-box;
  transition: .3s ease;
}


.button--green {
  background: #00BB28;
  border-color: #00BB28;
}

.button--primary {
  background-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 157, 249, 0.2);

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #00A8FF;
  }
}

.button--small {
  height: 40px;
  min-width: 96px;
}

.button--label {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  font-size: .875rem;
  font-weight: 500;
  color: var(--primary-color);
  border: transparent;
  background-color: transparent;

  &:hover {
    @media (--viewport-desktop) {
      background-color: rgba(0, 168, 255, 0.08);
    }
  }
}

.button--large {
  height: 56px;
  min-width: 233px;
  padding-left: 24px;
  padding-right: 24px;
}

.button--stroke {
  border: 1px solid rgba(0, 168, 255, 0.6);
  color: var(--primary-color);

  &:hover {
    border-color: var(--primary-color);
  }
}

.button--stroke-white {
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: var(--color-white);

  &:hover {
    border-color: var(--color-white);
  }
}

.button--secondary {
  background-color: var(--primary-color-dark);
  box-shadow: 0 4px 12px rgba(19, 33, 74, 0.12);

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), var(--primary-color-dark);
  }
}
