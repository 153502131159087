.link {
  font-size: .875rem;
  line-height: 1.42;
  transition: color 0.3s ease;
  cursor: pointer;
  background: none;
  border-right: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.link--blue {
  color: #00A8FF;
}