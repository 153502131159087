html {
    font-size: 1rem;
}

body {
    font-size: 1rem;
}

body, html {
    width: 100%;
    height: 100%;
    color: var(--text-color-primary);
    font-family: 'ProximaNova', Arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    &.popup-open {
        height: 100vh;
        overflow-y: hidden;
    }
}

.wrapper {
    display: block;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media (--viewport-tablet) {
        padding: 0 40px;
    }

    @media (--viewport-desktop) {
        padding: 0 20px;
    }
}

::selection {
    color: var(--color-white);
    background: var(--primary-color);
    background-color: #00A8FF; /*Edge fix*/
}
